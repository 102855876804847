<template>
  <div class="scan">
    <van-empty :description="description" />
  </div>
</template>

<script>
import {  Empty  } from "vant";
import { lockScreen } from "@/api/lockscreen";
import { getQueryVariable } from "@/common/utils";
import { mapState } from "vuex";
export default {
  name: "qrcode",
  components: {
    [Empty .name]: Empty,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      description:'解锁成功'
    };
  },
  mounted() {
    lockScreen({
      number: getQueryVariable('state'),
      phone: this.userInfo.account,
    }).then(res=>{
      this.description = res.msg
    }).catch(err=>{
      this.description = err.toString()
    })
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>